/**
 * 文件上传下载相关接口
 */
import service from '../request'

const baseUrl = window.globalUrl.HOME_API

// 上传文件
export const uploadFile = (param) => {
    return service({
        method: 'post',
        url: `${baseUrl}/admin/file/upload`,
        data: param
    })
}


// 下载文件,目前参数是上传返回的整个url
export const downLoadFile = (param) => {
    return service({
        method: 'get',
        url: `${baseUrl}/admin/file/get`,
        params: param
    })
}
//删除文件,目前参数是上传返回的整个url
export const deleteFile = (param) => {
    return service({
        method: 'get',
        url: `${baseUrl}/admin/file/delete`,
        params: param
    })
}
