/**
 * 项目管理接口管理
 */

import service from '../request'
const baseUrl = `${window.globalUrl.HOME_API_WEI}/trade-website/cqjyService`;
//我有地,找托管查询
export const getCompanyList = (params) => {
  return service({
    method: 'get',
    url: `${window.globalUrl.HOME_API_WEI}/trade-website/company/getCompanyList`,
    params: params,
    isForm: true
  })
}
//我找地,要接管查询
export const getOrderList = (params) => {
  return service({
    method: 'get',
    url: `${window.globalUrl.HOME_API_WEI}/trade-website/order/getOrderList`,
    params: params,
    isForm: true
  })
}
//我要接管发布、修改
export const companysave = (params) => {
  return service({
    method: 'post',
    url: `${window.globalUrl.HOME_API_WEI}/trade-website/text/save`,
    data: params,
  })
}
//我要接管分页查询
export const getCompanyToPage = (params) => {
  return service({
    method: 'get',
    url: `${window.globalUrl.HOME_API_WEI}/trade-website/company/getCompanyToPage`,
    params: params,
    isForm: true
  })
}
//我要托管编辑、修改
export const wytgAdd = (params) => {
  return service({
    method: 'post',
    url: `${window.globalUrl.HOME_API_WEI}/trade-website/order/save`,
    data: params,
  })
}
//我要托管分页查询
export const getToPage = (params) => {
  return service({
    method: 'get',
    url: `${window.globalUrl.HOME_API_WEI}/trade-website/order/getToPage`,
    params: params,
  })
}
//已接管土地分页查询
export const takenOrderToPage = (params) => {
  return service({
    method: 'get',
    url: `${window.globalUrl.HOME_API_WEI}/trade-website/order/takenOrderToPage`,
    params: params,
    isForm: true,
  })
}
//收藏的托管土地分页查询
export const getCollectionToPage = (params) => {
  return service({
    method: 'get',
    url: `${window.globalUrl.HOME_API_WEI}/trade-website/company/getCollectionToPage`,
    params: params,
    isForm: true,
  })
}

// 农户查看服务商
export const selectCompanyLand = (params) => {
  // return service.get(`${baseUrl}/trade-website/order/selectCompanyLand`, params)
  return service({
    method: 'get',
    url: `${window.globalUrl.HOME_API_WEI}/trade-website/order/selectCompanyLand`,
    params: params,
    isForm: true,
  })
}

// 资源资产收藏
export const selectCollectionTrade = (params) => {
  // return service.get(`${baseUrl}/trade-website/order/selectCompanyLand`, params)
  return service({
    method: 'get',
    url: `${window.globalUrl.HOME_API_WEI}/trade-website/cqjy/selectCollectionTrade`,
    params: params,
    isForm: true,
  })
}
// 发布需求信息
export const cancelCollectionXMXX = (params) => {
  return service({
    method: 'post',
    url: `${window.globalUrl.HOME_API_WEI}/trade-website/cqjy/cancelCollectionXMXX`,
    data: params,
    isForm: true
  })
}
// 发布需求信息
export const reportNeedsInfo = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/publishDemandInfo`,
    data: params,
    isForm: true
  })
}

// 发布供应信息
export const reportSupplysInfo = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/publishSupplyInfo`,
    data: params,
    isForm: true
  })
}

// 查询我的需求信息
export const getMyDemandInfo = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/getDemandInfoByUser`,
    data: params,
    isForm: true
  })
}

// 编辑我的需求信息
export const editDemandInfo = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/editDemandDetailInfo`,
    data: params,
    isForm: true
  })
}

// 需求信息条件查询
export const getDemandInfo = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/getDemandInfo`,
    data: params,
    isForm: true
  })
}

// 删除我的需求信息
export const deleteMyDemandInfo = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/deleteDemandInfo`,
    data: params,
    isForm: true
  })
}

// 项目信息查询
export const getProjectInfo = (params) => {
  return service({
    method: 'post',
    // url: `${baseUrl}/getItemInfo`,
    url: `${baseUrl}/getItemInfoByUid`,
    data: params,
    isForm: true
  })
}

// 供应信息查询
export const getSupplyInfo = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/getSupplyInfo`,
    data: params,
    isForm: true
  })
}

// 已报名项目
export const getSignUpProInfo = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/getJoinItemInfo`,
    data: params,
    isForm: true
  })
}

// 获取网络大厅竞价信息
export const getBidInfo = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/getBidInfo`,
    data: params,
    isForm: true
  })
}

// 大厅竞价信息
export const getDtBidInfo = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/getDtBidInfo`,
    data: params,
    isForm: true
  })
}

// 竞价信息录入
export const importBidInfo = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/importBidInfo`,
    data: params,
    isForm: true
  })
}

// 京价前获取当前报价
export const getCurrentOffer = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/getDqbhByXmbh`,
    data: params,
    isForm: true
  })
}

// 获取已结束项目列表
export const getEndProInfo = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/getEndItemInfo`,
    data: params,
    isForm: true
  })
}


// 上传地块图片
export const uploadLandPic = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/upLoadDkImages`,
    data: params,
    // isForm: true
  })
}

// 文件上传
export const uploadPic = (params) => {
  return service({
    method: 'post',
    url: `${window.globalUrl.HOME_API_WEI}/land-trustship/file/upload`,
    data: params,
  })
}


// 上传产权凭证
export const uploadPropertyPic = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/upLoadCqpzImages`,
    data: params,
    // isForm: true
  })
}

// 检查是否报名
export const checkJoinPro = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/checkJoinItemInfo`,
    data: params,
    isForm: true
  })
}

// 报名
export const joinInProject = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/importJoinInfo`,
    data: params,
    isForm: true
  })
}

// 报名缴费信息录入
export const paymentForProject = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/importBmjfInfo`,
    data: params,
    isForm: true
  })
}


// 缴费记录
export const getPaymentList = (params) => {
  return service({
    url: `${baseUrl}/getBmjfInfo`,
    method: 'post',
    data: params,
    isForm: true
  })
}

// 获取行政区划
// 2级
export const getRegionList = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/getXzqhInfo`,
    data: params,
  })
}
// 3级
export const getXzqhInfoCity = () => {
  return service({
    method: 'post',
    url: `${baseUrl}/getXzqhInfoToCity`,
  })
}

//分页查询个人已报名项目
export const getSignedUpXmxxByPg = (params) => {
  return service.post(`${window.globalUrl.HOME_API}/trade-website/cqjy/querySignedUpXmxxByPg`, params)
}

//查看收款账号
export const getAccount = (params) => {
  return service({
    method: 'get',
    url: `${window.globalUrl.HOME_API}/trade-website/cqjy/showAccount`,
    params: params
  })
}

//保存保证金凭据
export const savePzxx = (params) => {
  return service({
    method: 'get',
    url: `${window.globalUrl.HOME_API}/trade-website/cqjy/savePzxx`,
    params: params
  })
}

//个人中心--我的竞价结果查看
export const getMyBiddingResult = (params) => {
  return service.post(`${window.globalUrl.HOME_API}/trade-website/cqjy/myBiddingResult`, params)
}

//个人中心--我的竞价大厅
export const getMyBiddingHall = (params) => {
  return service.post(`${window.globalUrl.HOME_API}/trade-website/cqjy/myBiddingHall`, params)
}

//分页查询已发布的资源资产供应信息
export const getBiddingXmxxByPg = (params) => {
  return service({
    url: `${window.globalUrl.HOME_API}/trade-website/cqjyService/queryBiddingXmxxByPg`,
    method: 'post',
    params: params,
  })
}

//详情接口是否报名成功
export const saveJjbm = (params) => {
  return service({
    method: 'get',
    url: `${window.globalUrl.HOME_API}/trade-website/cqjy/jjbm`,
    params: params
  })
}

//查看收款账号
export const showAccount = (params) => {
  return service.get(`${window.globalUrl.HOME_API}/trade-website/cqjy/showAccount`, { params })
}

//预览项目合同
export const viewContract = (params) => {
  return service({
    url: `${window.globalUrl.HOME_API}/trade-website/cqjy/exportContract`,
    method: 'post',
    params: params,
    responseType: "blob",
  })
}

//导出项目合同检查
export const checkContract = (params) => {
  return service({
    url: `${window.globalUrl.HOME_API}/trade-website/cqjy/exportContractCheck`,
    method: 'post',
    params: params,
  })
}

//分页查询保证金审核列表
export const queryBmjlAuditByPg = (params) => {
  return service.post(`${window.globalUrl.HOME_API}/trade-website/cqjy/audit/queryBmjlAuditByPg`, params)
}

//保证金审核
export const bzjAudit = (params) => {
  return service({
    url: `${window.globalUrl.HOME_API}/trade-website/cqjy/audit/bzjAudit`,
    method: 'post',
    params: params,
  })
}

//分页查询公告时间
export const queryGgsjByPg = (params) => {
  return service.post(`${window.globalUrl.HOME_API}/trade-website/yy/ggsj/queryByPg`, params)
}

//新增公告时间
export const addGgsj = (params) => {
  return service.post(`${window.globalUrl.HOME_API}/trade-website/yy/ggsj/add`, params)
}

//更新公告时间
export const editGgsj = (params) => {
  return service.post(`${window.globalUrl.HOME_API}/trade-website/yy/ggsj/edit`, params)
}

//删除公告时间
export const deleteGgsj = (params) => {
  return service.post(`${window.globalUrl.HOME_API}/trade-website/yy/ggsj/delete`, params)
}

//分页查询各县银行账户信息
export const getBankByPg = (params) => {
  return service({
    url: `${window.globalUrl.HOME_API}/trade-website/yy/bank/queryBankByPg`,
    method: 'post',
    params: params,
  })
}

//删除县银行账户信息
export const delBank = (params) => {
  return service({
    url: `${window.globalUrl.HOME_API}/trade-website/yy/bank/delBank`,
    method: 'post',
    params: params,
  })
}

//编辑各县银行账户信息
export const editBank = (params) => {
  return service.post(`${window.globalUrl.HOME_API}/trade-website/yy/bank/editBank`, params)
}

//编辑各县银行账户信息
export const saveBank = (params) => {
  return service.post(`${window.globalUrl.HOME_API}/trade-website/yy/bank/saveBank`, params)
}

//上传托管合同

export const uploadOrderContract = (params) => {
  return service({
    method: 'post',
    url: `${window.globalUrl.HOME_API}/trade-website/order/uploadOrderContract`,
    data: params
  })
}
//查看托管合同
export const selectOrderContract = (params) => {
  return service({
    method: 'get',
    url: `${window.globalUrl.HOME_API}/trade-website/order/selectOrderContract`,
    params: params
  })
}
